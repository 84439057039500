import { useRef, useState } from 'react';

type ModalOptionsType = {
  defaultOpen?: boolean;
  afterOpen?: () => void;
  afterClose?: () => void;
};

const useModal = <T extends Record<string, any>>(
  defaultModalProps: T = {} as T,
  options?: ModalOptionsType,
) => {
  const { afterOpen, afterClose, defaultOpen = false } = options || {};
  const cacheKeyRef = useRef(0);
  const [modal, setModal] = useState(defaultOpen);
  const [modalProps, setModalProps] = useState<T>(defaultModalProps);

  const openModal = (newModalProps = {} as T) => {
    setModalProps(newModalProps);
    setModal(true);
    if (afterOpen) {
      afterOpen();
    }
  };

  const closeModal = () => {
    setModalProps(defaultModalProps);
    setModal(false);
    cacheKeyRef.current += 1;
    if (afterClose) {
      afterClose();
    }
  };

  const toggleModal = (newModalProps = {} as T) => {
    if (modal) {
      closeModal();
    } else {
      openModal(newModalProps);
    }
  };

  return {
    modal,
    modalKey: cacheKeyRef.current,
    modalProps,
    toggleModal,
    closeModal,
    openModal,
    setModalProps,
  };
};

export default useModal;
